import styled from "styled-components";

const BlogPostWrapper = styled.section`
  padding-top: 140px;
  padding-bottom: 160px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  max-width: 720px;
  margin: 0 auto;

  hr {
    position: relative;
    display: block;
    width: 100%;
    margin: 1.8em 0 2.4em;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid #e3e9ed;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

textarea {
    resize: vertical;
}

p,
ul,
ol,
dl,
blockquote {
    margin: 0 0 1.5em 0;
}

ol,
ul {
    padding-left: 1.3em;
    padding-right: 1.5em;
}

ol ol,
ul ul,
ul ol,
ol ul {
    margin: 0.5em 0 1em;
}

ul {
    list-style: disc;
}

ol {
    list-style: disc;
}

ul,
ol {
    max-width: 100%;
    list-style: disc;
}

li {
    margin: 0.5em 0;
    padding-left: 0.3em;
    line-height: 1.6em;
    list-style: disc;
    color: #3c484e;
}

dt {
    float: left;
    margin: 0 20px 0 0;
    width: 120px;
    font-weight: 500;
    text-align: right;
}

dd {
    margin: 0 0 5px 0;
    text-align: left;
}

  blockquote {
    margin: 0.3em 0 1.8em;
    padding: 0 1.6em 0 1.6em;
    border-left: #cbeafb 0.5em solid;
}

blockquote p {
    margin: 0.8em 0;
    font-size: 1.2em;
    font-weight: 300;
}

blockquote small {
    display: inline-block;
    margin: 0.8em 0 0.8em 1.5em;
    font-size: 0.9em;
    opacity: 0.8;
}



blockquote cite {
    font-weight: bold;
}
blockquote cite a {
    font-weight: normal;
}

  @media (max-width: 990px) {
    padding-top: 180px;
    padding-bottom: 60px;
    min-height: auto;
  }
  @media (max-width: 767px) {
    padding-top: 130px;
    padding-bottom: 40px;
    min-height: auto;
  }

  @media only screen and (max-width: 480px) {
    min-height: 100vh;
    background: none;
    padding-top: 100px;
  }

  .content-body {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.content-body p {
    color: #3c484e;
}

.content-body h1 {
    margin: 1.0em 0 0.5em 0;
    font-size: 3rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .content-body h1 {
        font-size: 2.4rem;
    }
}

.content-title {
    margin: 0 0 0.2em;
    font-size: 4rem;
    text-align: center;
}
@media (max-width: 500px) {
    .content-title {
        margin: 0.8em 0;
        font-size: 3rem;
    }
    .content {
        font-size: 1.8rem;
    }
}

.content-body h2 {
    margin: 0.8em 0 0.4em 0;
    font-size: 2.4rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .content-body h2 {
        font-size: 2rem;
    }
}

.content-body h3 {
    margin: 1em 0 0.5em 0;
    font-size: 2rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .content-body h3 {
        font-size: 1.8rem;
    }
}

.content-body h4 {
    margin: 1em 0 0.5em 0;
    font-size: 1.5rem;
    font-weight: 700;
}
@media (max-width: 500px) {
    .content-body h4 {
        font-size: 1.5rem;
    }
}

.content-body figure {
    margin: 0.4em 0 1.6em;
    font-size: 2.8rem;
    font-weight: 700;
}

.content-body pre {
    margin: 0.4em 0 1.8em;
    font-size: 1.6rem;
    line-height: 1.4em;
    white-space: pre-wrap;
    padding: 20px;
    color: #fff;
    border-radius: 12px;
}

  .post-full-content {
    max-width: 720px;
    margin: 0 auto;
    background: #fff;
    font-size: 1.2rem;
  }

  .post-feature-image {
    margin: 0 auto;
    width: 100%;
    height: 360px;
    object-fit: cover;
    }

    .post-card-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 0 1.5rem 0;
        color: gray;
    }

    @media (max-width: 500px) {
        .post-card-footer {
            flex-direction: column
        }
    }
    
    .post-card-footer-left {
        display: flex;
        align-items: center;
        margin-right: 5px;
    }
    
    .post-card-footer-right {
        font-size: 0.9rem;
    }
    
    .post-card-avatar {
        width: 30px;
        height: 30px;
        margin: 0 7px 10px 0;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .post-card-avatar .author-profile-image {
        display: block;
        width: 100%;
        height: 100%;
        background: #5B7A81;
        border-radius: 100%;
        object-fit: cover;
    }

    .post-card-avatar-big {
        width: 80px;
        height: 80px;
        margin: 0 16px 10px 0;
        border-radius: 100%;
        display: flex;
        flex: 1,
    }
    
    .post-card-avatar-big .author-profile-image {
        display: block;
        width: 80px;
        height: 100%;
        background: #5B7A81;
        border-radius: 100%;
        object-fit: cover;
    }

    .post-card-footer-bottom {
        display: flex;
        margin-right: 5px;
    }
    
    .post-card-avatar .default-avatar {
        width: 26px;
    }
`;

export { BlogPostWrapper };